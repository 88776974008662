@import "./Global.scss";

/* Education Component */


.certificationTitle{
  text-align: center;
  width: 100%;
  font-family: 'DynaPuff', cursive;
  
}
.educationSection{
    min-height: 85vh;
}
/* Education Card */
.educationCard {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 700px;
    padding: 20px;
    text-align: left;
  }
  
  /* Name */
  .name {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 10px;
  }
  
  /* Education Meta Data */
  .educationMetaData {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 10px;
    font-size: 14px;
  }
  
  .educationMetaData p {
    margin: 0;
  }
  
  .educationMetaData span {
    font-weight: bold;
  }
  
  /* Education Description */
  .educationDescription {
    margin: 0 0 10px;
    font-size: 16px;
  }
  
  .educationDescription p {
    margin: 0;
  }
  
  /* Courses and Awards */
  .coursesAndAwards {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }
  
  .coursesAndAwards ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .coursesAndAwards li {
    margin: 1px;
    padding: 5px;
    border-radius: 1rem;
    display: inline-block;
    color: #D1D5DB;
    background-color: #343541;
  }
  
  .randomImages {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .educationImages {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }