#skills h2{
    text-align: center;
    font-family: 'DynaPuff', cursive;

}

#skillsDisplay{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
}

