@import "./Global.scss";

.landingPage{
    width: 100%;
    height: 100vh;
    background-size: cover;
    font-size: 35px;
    font-weight: 600;
    color: white;
    font-family: 'Roboto Slab', serif;}
.skit1{
    position: absolute;
    top: 10vh;
    left: 2vw;

    span{
        color: orangered;
    text-shadow: 0 0 5px orange;
    background-color: rgba(0,0,0,0.5);
    white-space: nowrap;
    

    }
}

.skit2{
    position: absolute;
    bottom: -5vh;
    text-align: center;
    width: 100%;
}
.profileImage{
    width: 100%;
    max-width: 300px;
    object-fit: cover;
    border-radius: 50%;
    box-sizing: border-box;
    padding: 10px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -35%);

}