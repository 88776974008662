
@import "./Global";

.specificSkill{
    background-color: color(white);
    color: black;
    width: 90%;
    margin: 2px auto;
    border: 2px solid color(orange);
    position: relative;
    height: 1.25rem;
    padding: 2px;


    .percentageBar{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: color(lightergreen);
        transition: width 0.5s ease;
        z-index: 1;
        

    }
    .skillName{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        color:color(darkgreen);
        z-index: 2;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        
    }
}

@keyframes progressBar{
    from{
        width: 0; 
    }
    to{
        width: 100%
    }
}

@media (max-width: 460px){
    .skillName{
        font-size: 1rem;
    }
}