@import "./Global.scss";

#navbar{
    background-color: rgb(58, 81, 75);
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    color: #EDEEED;
    min-height: 8vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: 'Signika Negative', sans-serif;
    align-items: center;
    z-index: 5;


}

.avatar{
    width: 50px;
    object-fit: contain;
    border-radius: 50%;
    order: 1;
    display: none;
    cursor: pointer;
    transform: translateX(50%);
    transition: all 0.2s ease-in-out;

    &:hover{
        box-shadow: 0 0 10px color(lightGreen);

    }
    
}
.resume{
    order: 2;
    padding: 0 20px;
    background-color: rgb(226, 171, 68);
    height: 8vh;
    line-height: 8vh;
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: 500;
    transition: all 0.2s ease-in-out;


    &:hover{
        background-color: rgb(222, 180, 102);
        color: color(darkgreen);
    }
}
.navElements{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;

    :first-child{
        border: none;
    }
    
    
    
}
.navlink{
    text-decoration: none;
    text-align: center;
    width: 100%;
    height: 100%;
    color: color(lightGreen);
    cursor: pointer;
    font-size: 1.25rem;
    transition: all 0.2s ease-in-out;
    &:hover{
        color: orange;
        text-shadow: 0 0 1px orange;
        transform: translateY(-5px);

    }
   
}



.openNavIcon{
    
    display: none;
    cursor: pointer;
    width: auto;
    object-fit: contain;
    transition: all 0.2s ease-in-out;

    &:hover{

        color: orange;
        transform: scale(1.1);
    }

}



@media screen and (max-width: 700px){

    
    .openNavIcon{
        display: block;
    }
    .navElements{
        color: color(darkGreen);
        display: flex;
        flex-direction: column;
        position: absolute;

        left: 0;
        top: 100%;
        height: 92vh;
        width: 100%;
        max-width: 425px;
        background-color: #edeeed;
        font-size: 2rem;
        transition-property: all;
        transition-duration: 0.5s;

    

    }

    .navlink {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    // need to be placed after navElements block to work
    .close{
        left: -999px;
        transition-property: all;
        transition-duration: 0.5s;
    }
    .avatar{
        display: block;
    }



}