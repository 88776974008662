@import "./Global.scss";

$desktop: 992px;
.aboutMeTitle{
  font-family: 'DynaPuff', cursive;
  font-size: 3rem;
  text-align: center;
  margin: 3rem 0;
}
.aboutMeBlocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }
  
  .block {
    width: 100%;
    max-width: 800px;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    background-color: #C27F96;
    color: black

  }
  
  .block h3 {
    margin: 0;
    font-size: 1.5rem;
  }
  
  .block p {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .sentence {
    margin: 0.5rem 0;
    list-style: none;
  }
  
  .aboutMeImages {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .aboutMeRandomImg {
    width: 100%;
    max-width: 400px;
    height: auto;
  }
  
  /* Responsive styles for tablet and up */
  @media (min-width: 768px) {
    .aboutMeTitle {
      font-size: 2.5rem;
    }
  
    .aboutMeBlocks {
      gap: 4rem;
    }
  
    .block {
      width: 45%;
    }
  }
  
  /* Responsive styles for desktop and up */
  @media (min-width: 1024px) {
    .aboutMeTitle {
      font-size: 3rem;
    }
  
    .block {
      width: 30%;
    }
  
    .aboutMeImages {
      gap: 2rem;
    }
  
    .aboutMeRandomImg {
      max-width: 600px;
    }
  }