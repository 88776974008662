@import "./Global.scss";

.contacts{
    background-color: color(darkgreen);
    padding: 10px;
    
    margin-bottom: 0;
    margin-top: auto;

    // .contactMainContent{
    //     display: flex;
    // }

    h2{
        text-align: center;
        color: color(white);
        font-family: 'DynaPuff', cursive;

    }

    form{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;

        input{
            width: 100%;
            height: 6vh;
            font-size: larger;
            padding: 0 10px;
            line-height: 6vh;
            border-radius: 20px;
            margin: 20px 0;
            box-sizing: border-box;

        }

        textarea{
            width: 100%;
            height: 15vh;
            padding: 10px;
            border-radius: 20px;
            line-height: 2vh;
            font-size: large;
            margin: 10px 0;
            box-sizing: border-box;

        }

        button{
            max-width: 200px;
            height: 5vh;
            border-radius: 10px;
            background-color: rgb(205, 143, 34);
            border: 0;
            color: color(white);
            font-size: larger;
            font-weight: 500;
            margin: 10px;
            cursor: pointer;
            box-shadow: 0 0 10px cyan;
            text-shadow: 0 0 10px orangered;
        }
    }

    .contactsLinks{
        display: flex;
        flex-wrap: wrap;
        
        .link{
            padding: 10px;
               
            & > *{
                transition: all 0.2s ease-in-out;
        border-radius: 50px;


                &:hover{
                    transform: scale(1.1);
            cursor: pointer;
                }
            }

        
        }    
      
    }
    
    
    .discordIcon{
        width: 50px;
        object-fit: contain;
    
    }
}
