
.categoryBubble{
    max-width: 90%;
    width: 200px;
    height: 200px;
    background-color: aliceblue;
    margin: 20px 5px;
    border-radius: 50%;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.7), 0px 0px 20px rgba(255, 255, 255, 0.5), 0px 0px 30px rgba(255, 255, 255, 0.3), 0px 0px 40px rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: border-radius 0.3s ease-in-out;
    animation: float 2s ease-in-out infinite;
    animation-direction: alternate;
    text-transform: capitalize;
    font-weight: 500;
    font-size: larger;
    user-select: none;
    overflow-y: auto;

    &::-webkit-scrollbar{
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background-color: #f1f1f1; 
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: #7e7e7e; 
        border-radius: 4px; 
      }
    
      &::-webkit-scrollbar-thumb:hover {
        background-color: #397380; 
      }
    

    .relativeSkills{
        display: none;
    }

}


.categoryBubble:hover{
    cursor: pointer;
    
}

.categoryBubble:nth-child(odd){
    animation-name: float_reverse;
}

.expandedBubble{    
    border-radius: 0;
    max-width: 100%;
    width: 600px    ;
    animation: none;
    display: block;

    .title{
        margin: 10px 0;
    }
    .relativeSkills{
        display: block;
        list-style-type: none;
        text-align: center;
        padding: 0;
    }     
    
}

@keyframes float{
    
    from{
        transform: translate(0,-5%) rotate(10deg);

    }
    to{
        transform: translate(0,5%) rotate(-10deg);
        
    }
    
}
@keyframes float_reverse{
    
    from{
        transform: translate(0,5%) rotate(-10deg);

        
    }
    to{
        transform: translate(0,-5%) rotate(10deg);

    }
    
}