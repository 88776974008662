
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Roboto+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Signika+Negative&display=swap');
$palette : (
    darkgreen: rgb(58, 81, 75),
    lightgreen: rgb(150, 187, 194),
    white: aliceBlue,
    orange: rgb(199, 151, 61),
    lightergreen: #b5deed,
    

);

@function color($name){
    @return map-get($palette, $name);
}

