.projectSection{
    background-color:#df511d;
    padding: 20px 0;
    transition: background-color 0.5s ease-in-out;

    h2{
        text-align: center;
        font-family: 'DynaPuff', cursive;
    }
}


.projectSlider{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.projectCarousel{
    margin: 10px;
    width: 100%;
    max-width: 90vw;

    box-sizing: border-box;
    position: relative;

    .githubOverlay{
        position: absolute;
        top: 0;
        height: 100%;
        left: 0;
        width: 100%;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

    }

    :hover{
        .githubOverlay{
            opacity: 1;
            cursor: pointer;

        }
    }


}   
.projectCard{
    

    width: 100%;
    object-fit: contain;
    background-color: antiquewhite;
    
}
.projectDetail{
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;


    h3{
        display: inline-block;
        padding-right: 10px;
    }

    // Sample languages
    .languages {
        display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    gap: 10px;
    align-items: center;
    }

   
    // .language{
    //     display: flex;
    //     margin: 10px 0;
    //     align-items: center;
    //     width: 100%;
    //     max-width: 400px;

    //     .percentageBar{
    //         height: 10px;
    //         position: relative;
    //         margin: 0 10px;
    //         flex: 1;


    
    //         .percentageBarFill{
    //             position: absolute;
    //             top: 0;
    //             left: 0;
    //             height: 100%;
    //             width: 0%;
                
    //             border-radius: 10px 0  0 10px ;
    //             animation: loadPercentages 2s ease-in-out;

    //         }
    //     }

    //     p{
    //         margin: 0 10px;

    //     }
    // }
    .language {
        display: contents; // allows children to take the place of the parent in the grid
  
        .languageName {
          grid-column: 1 / 2;
          // Other styling like padding, font-size, etc.
        }
  
        .percentageBar {
          grid-column: 2 / 3;
          height: 20px;
          background-color: #e0e0e0;
          border-radius: 4px;
          overflow: hidden;
  
          .percentageBarFill {
            height: 100%;
            border-radius: 4px 0 0 4px;
          }
        }
  
        .percentageValue {
          grid-column: 3 / 4;
          text-align: right;
          // Other styling like padding, font-size, etc.
        }
      }
 


    .screenShots{
        display: flex;
        overflow-x: scroll;
        width: 100%;
        margin: 20px 0;

        img{
            height: 100px;
            object-fit: contain;
            margin: 10px;
            cursor: pointer;
          

        }

        .activeScreenShot{
            border: 2px solid orange;
            box-shadow: 0 0 10px orange;
        }
    }
}


@keyframes loadPercentages {
    from {
        width: 0;
    }
    to {
        width: 100;
    }
}